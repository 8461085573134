<template>
  <a-drawer height="500" width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">

      <a-form-model-item v-show="formType === 3" label="图片" prop="image" v-if="form.image">
        <img style="width: 150px;margin-top: 5px;margin-right: 5px;" v-if="form.image"
             v-for="(guildLogo,imgIndex) in form.image.split(',')"
             :src="guildLogo"
             preview="图片" />
      </a-form-model-item>

      <a-form-model-item v-show="formType !== 3" label="回复内容" prop="handleContent" >
        <a-input v-model="form.handleContent" placeholder="请输入内容" type="textarea"  rows="5" allow-clear  />
      </a-form-model-item>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" v-show="formType !== 3" :loading="submitLoading" @click="submitForm">
            确定
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getFeedback, addFeedback, updateFeedback, handleFeedback } from '@/api/biz/feedback'
import {getAttestE} from "@/api/biz/attestE";

export default {
  name: 'HandleForm',
  props: {
    typeOptions: {
      type: Array,
      required: true
    },
    handleFlagOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '回复处理',
      // 表单参数
      form: {
        id: null,

        userId: null,

        type: null,

        content: null,

        image: null,

        contactWay: null,

        handleTime: null,

        handleFlag: null,

        handleContent: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        handleContent: [
          { required: true, message: '回复内容不能为空', trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        type: null,
        content: null,
        image: null,
        contactWay: null,
        handleTime: null,
        handleFlag: null,
        handleContent: null,
        createTime: null,
        remark: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getFeedback({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '回复处理'
      })
    },
    handleDetail (row, ids) {
      this.reset()
      this.formType = 3
      const id = row ? row.id : ids
      getFeedback({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '详情'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            this.form.handleFlag = 1
            handleFeedback(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {

          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
